import * as React from "react"

import Seo from "../components/seo"
import Header from "../components/Header"

const NotFoundPage = () => (
    <div className="error"> 
      <Header/>
      <Seo title="404: Not found" />
      <h1>404: Not Found</h1>
      <p>Sorry this page does not exist!</p>
  </div>


)

export default NotFoundPage
